:root {    
    /* Typography */
    // Base font is 10px
    --size-font-100: 0.75rem;
    --size-font-200: 0.875rem;
    --size-font-300: 1rem;
    --size-font-325: 1.1rem;
    --size-font-350: 1.2rem;
    --size-font-400: 1.4rem;
    --size-font-500: 1.75rem;
    --size-font-600: 2.0rem;
    --size-font-700: 2.45rem;
    --size-font-800: 3.15rem;
    --size-font-900: 4rem;

    /* Margin and Padding */
    --size-spacing-0: 0rem;
    --size-spacing-050: 0.25rem;
    --size-spacing-100: 0.5rem;
    --size-spacing-150: 0.75rem;
    --size-spacing-200: 1rem;
    --size-spacing-300: 1.5rem;
    --size-spacing-400: 2rem;
    --size-spacing-500: 2.5rem;
    --size-spacing-600: 3rem;
    --size-spacing-700: 3.5rem;
    --size-spacing-800: 4rem;
    --size-spacing-900: 4.5rem;

    /* Default Colors */
    --color-white: #ffffff;
    --color-black: #000000;
    --color-white-rgb: 255, 255, 255;
    --color-black-rgb: 0, 0, 0;
    
    --color-neutral-100: #F7F7F7;
    --color-neutral-200: #E1E2E2;
    --color-neutral-300: #949598;
    --color-neutral-400: #76767B;
    --color-neutral-500: #68686D;
    --color-neutral-550: #717689;
    --color-neutral-600: #5C5D62;
    --color-neutral-700: #35363D;
    --color-neutral-800: #32343E;
    --color-neutral-900: #25262D;

    --color-purple-100: #e5daf8;
    --color-purple-200: #d3bef4;
    --color-purple-300: #b795ec;
    --color-purple-400: #a379e7;
    --color-purple-500: #8952e0;
    --color-purple-600: #7434db;
    --color-purple-700: #6023c0;
    --color-purple-800: #4f1d9e;
    --color-purple-900: #3b1676;

    --color-pink-100: #f8d9e7;
    --color-pink-200: #f3b9d3;
    --color-pink-300: #eb8db8;
    --color-pink-400: #e56ba2;
    --color-pink-500: #dc3882;
    --color-pink-600: #c4246c;
    --color-pink-700: #a01d58;
    --color-pink-800: #7d1745;
    --color-pink-900: #5d1133;

    --color-red-100: #f8d9d7;
    --color-red-200: #f2b7b4;
    --color-red-300: #ea8c87;
    --color-red-400: #e5726b;
    --color-red-500: #dd4840;
    --color-red-600: #c72d25;
    --color-red-700: #a1241e;
    --color-red-800: #891f19;
    --color-red-900: #641712;

    --color-orange-100: #f9ebdb;
    --color-orange-200: #f1d4b1;
    --color-orange-300: #e6b273;
    --color-orange-400: #dc9239;
    --color-orange-500: #c37b24;
    --color-orange-600: #a5681e;
    --color-orange-700: #835318;
    --color-orange-800: #674113;
    --color-orange-900: #553610;

    --color-yellow-100: #fbf9ea;
    --color-yellow-200: #f4eec2;
    --color-yellow-300: #ece192;
    --color-yellow-400: #dfce4b;
    --color-yellow-500: #bba922;
    --color-yellow-600: #95871c;
    --color-yellow-700: #746915;
    --color-yellow-800: #574f10;
    --color-yellow-900: #48410d;

    --color-green-100: #c6f5e0;
    --color-green-200: #83e9bb;
    --color-green-300: #28d889;
    --color-green-400: #23bd78;
    --color-green-500: #1ea367;
    --color-green-600: #198755;
    --color-green-700: #136942;
    --color-green-800: #105636;
    --color-green-900: #0d472d;

    --color-teal-100: #c0f1f4;
    --color-teal-200: #84e4e9;
    --color-teal-300: #2dd1da;
    --color-teal-400: #22b2ba;
    --color-teal-500: #1d979e;
    --color-teal-600: #187b80;
    --color-teal-700: #125f64;
    --color-teal-800: #0f5053;
    --color-teal-900: #0d4244;

    --color-cyan-100: #d0eef7;
    --color-cyan-200: #bae7f3;
    --color-cyan-300: #a2deee;
    --color-cyan-400: #53c2e1;
    --color-cyan-500: #2ab4d9;
    --color-cyan-600: #24a2c4;
    --color-cyan-700: #1e86a2;
    --color-cyan-800: #196e85;
    --color-cyan-900: #135567;

    --color-blue-100: #cde0f6;
    --color-blue-200: #a8c8f0;
    --color-blue-300: #7fafe8;
    --color-blue-400: #5896e1;
    --color-blue-500: #347fdb;
    --color-blue-550: #007aff;
    --color-blue-600: #236abf;
    --color-blue-700: #1b5192;
    --color-blue-800: #164278;
    --color-blue-900: #123662;

    /* Brand Colors */
    --color-brand-purple-50: #fbf5fe;
    --color-brand-purple-100: #eed9fb;
    --color-brand-purple-200: #dfb7f7;
    --color-brand-purple-300: #cc8cf2;
    --color-brand-purple-400: #c073ef;
    --color-brand-purple-500: #af4deb;
    --color-brand-purple-550: #B547E9;
    --color-brand-purple-600: #9839d2;
    --color-brand-purple-700: #7a2ea9;
    --color-brand-purple-800: #68278f;
    --color-brand-purple-900: #4b1c68;

    --color-brand-blue-50: #f6f7ff;
    --color-brand-blue-100: #dbdefd;
    --color-brand-blue-200: #bcc2fb;
    --color-brand-blue-300: #969ff9;
    --color-brand-blue-400: #808af8;
    --color-brand-blue-500: #616ef6;
    --color-brand-blue-600: #4756ef;
    --color-brand-blue-700: #3945c0;
    --color-brand-blue-800: #303aa3;
    --color-brand-blue-900: #232a76;


}